@font-face {
    font-family: 'Frutiger';
    src: url('../public/Frutiger.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Frutiger Bold';
    src: url('../public/Frutiger_bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


:root {
    --primary-bg: #1a1a1a;
    --secondary-bg: #2a2a2a;
    --border-color: #3a3a3a;
    --text-color: #ddd;
    --text-accent-color: #ebb400;
    --accent-color: #4CAF50;
}

/* Add these global styles */
* {
    font-family: 'Hind', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.app-title, .callsign,
h2 {
    text-transform: uppercase;
}

body {
    background-color: var(--primary-bg);
    color: var(--text-color);
}

.app-container {
    display: grid;
    grid-template-rows: 80px 1fr 40px;
    height: 880px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: var(--primary-bg);
    overflow: hidden;
}

.top-banner {
    display: grid;
    grid-template-columns: 82% 18%;
    align-items: center;
    padding: 0;
    border-radius: 15px;
    width: calc(100% - 40px);
    max-width: inherit;
    margin: 0 auto;
    box-sizing: border-box;
}

.app-title, .fa-solid {
    color: var(--text-accent-color);
    filter:drop-shadow(1px 2px 1px #000);
}

.app-title {
    font-family: 'Frutiger Bold', sans-serif;
    font-size: 42px;
}

.banner-left, .banner-right {
    background-color: var(--secondary-bg);
    display: flex;
    height: 100%;
    border-radius: 15px;
    border-bottom: 1px solid var(--border-color);

}

.banner-left {
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    padding-right: 20px;
}

.banner-right {
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}

.main-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 54fr 46fr;
    gap: 20px;
    padding: 20px;
    overflow: hidden;
    filter: drop-shadow(7px 10px 12px rgba(0, 0, 0, 0.4));
}

.aircraft-list {
    grid-row: 1 / -1;
    background-color: var(--secondary-bg);
    border-radius: 15px;
    padding: 20px;
    overflow-y: auto;
    height: 100%;  /* Fill the grid cell */
    max-height: 100%;  /* Prevent overflow */
    box-sizing: border-box;  /* Include padding in height calculation */
}

.map-container, .controls-container {
    background-color: var(--secondary-bg);
    border-radius: 15px;
    overflow: hidden;
    height: 100%;  /* Fill their respective grid cells */
    box-sizing: border-box;
}

.controls-container {
    padding:10px;
}

/* Input Styles */
.location-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 30px;
}

.location-inputs input[readonly] {
    background-color: var(--background-color);
    cursor: default;
}

.loading-countdown {
    position: relative;
    width: 120px;
    height: 20px;
    background: var(--primary-bg);
    border: 2px solid var(--border-color);
    border: 1px solid gray;
    border-radius: 12px;
    overflow: hidden;
    transition: opacity 1s ease;
    opacity: 0.7;
}

.loading-countdown:hover {
    opacity: 1;
}

.countdown-fill {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transform-origin: right;
    border-radius: 12px;
}

.warning-indicators {
    display: flex;
    gap: 15px;
    position: relative;  /* For tooltip positioning */
}

.warning-tooltip {
    position: absolute;
    top: calc(100% + 10px);  /* 10px below the indicators */
    right: 0;
    background-color: var(--secondary-bg);
    border: 1px solid var(--border-color);
    padding: 10px;
    border-radius: 6px;
    font-size: 0.9em;
    color: var(--text-color);
    white-space: nowrap;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Optional: Add a small arrow pointing up */
.warning-tooltip::before {
    content: '';
    position: absolute;
    top: -6px;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid var(--border-color);
}

.indicator {
    width: 60px;  /* Match height of countdown timer */
    height: 60px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    background-color: #808080;
}

/* https://cssgradient.io/, https://cloudconvert.com/png-to-webp  */

.indicator.gray { background-image: url('../public/images/indicator-off.webp'); }
.indicator.yellow { background-image: url('../public/images/indicator-yellow.webp'); }
.indicator.red { background-image: url('../public/images/indicator-red.webp'); }

.aircraft-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid var(--border-color);
}

.aircraft-item .callsign {
    font-weight: 600;
}

.aircraft-item .details {
    display: flex;
    gap: 15px;
    color: #888;
}

.input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.input-group label {
    font-size: 0.9em;
    color: #888;
    margin-left: 2px;
}

.input-group input {
    padding: 8px;
    border: 1px solid var(--border-color);
    background: var(--primary-bg);
    color: var(--accent-text-color);
    border-radius: 6px;
}

.sliders-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 5px 0;
}

.radius-control, .altitude-control {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.radius-slider, .altitude-slider {
    width: 100%;
    background: var(--border-color);
    border-radius: 18px;
    -webkit-appearance: none;
    appearance: none;
    height: 28px;  /* Thicker track */
    margin-bottom: 10px;
    border:1px solid rgb(70, 70, 70);
}

.radius-slider::-webkit-slider-thumb,
.altitude-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 28px;  /* Larger touch target */
    height: 28px;  /* Larger touch target */
    background: #fff;
    border-radius: 50%;
    border: 2px solid var(--text-accent-color);
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
}

.radius-slider::-moz-range-thumb,
.altitude-slider::-moz-range-thumb {
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 50%;
    border: 2px solid var(--text-accent-color);
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
}

/* Hover and active states */
.radius-slider::-webkit-slider-thumb:hover,
.altitude-slider::-webkit-slider-thumb:hover {
    transform: scale(1.1);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.radius-slider::-webkit-slider-thumb:active,
.altitude-slider::-webkit-slider-thumb:active {
    transform: scale(1.15);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Value labels */
.slider-label {
    font-size: 0.9em;
    color: #888;
    display: flex;
    align-items: center;
}

.slider-value {
    padding-left: 6px;
    font-weight: 600;
    color: var(--text-accent-color);
}


.update-frequency {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

.update-frequency-label{
    font-size: 0.9em;
    color: #888;
}

.footer {
    padding: 15px 20px;
    text-align: center;
    font-size: 0.8em;
    font-style: italic;
    color: #333;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.footer p {
    margin: 0;
}

.popup-button {
    opacity: 0.5;
    transition: opacity 0.2s;
    cursor: pointer;
    background: none;
    border: none;
    color: inherit;
    padding: 4px;
}

.popup-button:hover {
    opacity: 1;
}

.changelog-trigger {
    cursor: pointer;
    text-decoration: underline;
    position: relative;
    color:var(--border-color);
}

.warning-tooltip.changelog {
    position: absolute;
    bottom: 35px;
    right: 20px;
    background-color: var(--secondary-bg);
    border: 1px solid var(--border-color);
    padding: 20px;
    border-radius: 6px;
    font-size: 0.9em;
    color: var(--text-color);
    white-space: pre-line;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    text-align: left;
}

/* Update the arrow for changelog tooltip */
.warning-tooltip.changelog::before {
    top: auto;
    bottom: -6px;
    border-top: 6px solid var(--border-color);
    border-bottom: none;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(3px); 
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: var(--secondary-bg);
    opacity:0.925;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    width: 60vh;
    max-height: 80vh;
    overflow-y: auto;
    padding: 20px;
    position: relative;
    color:var(--text-color);
    text-align:left;
    text-transform:lowercase;
    margin-top:100px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal-header h2 {
    margin: 0;
    font-size: 1.8em;
    color: var(--text-color);

}

.modal-close {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 2em;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
}

.modal-close:hover {
    background: rgba(255, 255, 255, 0.1);
}

.controls-bottom {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.controls-row {
    display: flex;
    align-items: center;
    gap: 10px;
}

.timer-container {
    display: flex;
    align-items: center;
}

.pause-control {
    width: 255px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.pause-label {
    font-size: 0.9em;
    color: #888;
    text-align: center;
}

/* SVG Pie Timer colors */
.svg-loader {
    fill: #ebb400;
}

.svg-border {
    fill: #3a3a3a;
}

.update-controls {
    width: 255px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.app-header {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 20px;
}

.header-links {
    display: flex;
    gap: 30px;
    margin-left: auto;  /* Push links to the right */
    margin-right:20px;
}

.header-link {
    color: var(--text-accent-color);
    font-family: 'Frutiger Bold', sans-serif;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.7rem;
    font-weight: normal;
    transition: color 0.2s ease;
}

.header-link:hover {
    color: #ebb400;
}

/* Style overrides for Material-UI TextFields */
.location-inputs .MuiTextField-root .MuiOutlinedInput-root {
    background-color: var(--secondary-bg);
    border-radius: 15px;
    font-size: 1em;
    font-family: 'Hind', sans-serif;

}

.location-inputs .MuiTextField-root .MuiInputLabel-root,
.location-inputs .MuiTextField-root .MuiOutlinedInput-input {
    color: #888;
    font-size: 1em;
}

.location-inputs .MuiTextField-root .MuiOutlinedInput-notchedOutline {
    border-color: var(--border-color);

}

.modal-content p {
    margin-bottom: 10px;
}

.modal-content a {
    color: var(--text-accent-color);
}

.modal-content img {
    display:flex;
    margin:auto;
    padding-top:10px;
}
/* Optional: remove margin from last paragraph to avoid extra space at bottom */
.modal-content p:last-child {
    margin-bottom: 0;
}

.modal-content .fa-solid, .modal-content .fa-regular {
    vertical-align: middle;
    margin-right: 5px;  /* Optional: adds a small space between icon and text */
    color: var(--text-accent-color);
}

.modal-content .help-content p {
    padding-bottom: 20px;
}

.help-content .fa-icon {
    color: var(--text-accent-color); /* Your yellow color */
    margin-right: 10px;
    width: 20px;  /* Fixed width for alignment */
    text-align: center;
}